import { mapState, mapActions, mapGetters } from "vuex";

export const authComputed = {
  ...mapState( {
    username: (state) => state.username,
    email: (state) => state.email,
    rol: (state) => state.rol,
    oid: (state) => state.oid,
    token: (state) => state.token,
  }),
};



export const authMethods = mapActions([
  "setUsername",
  "setEmail",
  "setRol",
  "setOid",
  "setToken",
  "logout",
]);
 export const authGetters = mapGetters( [
  "getUsername",
  "getEmail",
  "getRol",
  "getOid",
  "getToken"
 ])

 export const layoutComputed = {
  ...mapState("layout", {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
  }),
};

export const layoutMethods = mapActions("layout", [
  "changeLayoutType",
  "changeLayoutWidth",
  "changeLeftSidebarType",
  "changeTopbar",
  "changeLoaderValue",
]);

export const notificationMethods = mapActions("notification", [
  "success",
  "error",
  "clear",
]);

export const todoComputed = {
  ...mapState("todo", {
    todos: (state) => state.todos,
  }),
};
export const todoMethods = mapActions("todo", ["fetchTodos"]);
