export const menuItems = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
  },
  {
    id: 2,
    label: "menuitems.dashboards.text",
    icon: "bx-home-circle",
    link: "/",
  },
  {
    id: 7,
    isLayout: true,
  },
  {
    id: 8,
    label: "menuitems.apps.text",
    isTitle: true,
  },
  {
    id: 9,
    label: "Company",
    icon: "bx bxs-business",
    link: "/company",
    subItems: [
      /*{
        id: 22,
        label: "Search  Group/ Company",
        link: "/company",
        parentId: 21,
      },*/
      {
        id: 22,
        label: " Practitioner/Coach/Member",
        link: "/Practitioner/Coach/Member",
        parentId: 21,
      },
      {
        id: 22,
        label: "$ Billing",
        link: "/Billing",
        parentId: 21,
      },
      /*{
        id: 22,
        label: "Client App Admin",
        link: "/client-app-admin",
        parentId: 21,
      },*/
      /*{
        id: 22,
        label: "Intelligence Libraries",
        link: "/intelligence-libraries",
        parentId: 21,
      },*/
      /*{
        id: 22,
        label: "Resources",
        link: "/resources",
        parentId: 21,
      },*/
      /*{
        id: 22,
        label: "Messaging",
        link: "/messaging",
        parentId: 21,
      },*/
      /*{
        id: 22,
        label: "Reports",
        link: "/reports",
        parentId: 21,
      },*/
      /*{
        id: 22,
        label: "Settings",
        link: "/settings",
        parentId: 21,
      },*/
    ],
  },
  {
    id: 10,
    label: "Client",
    icon: "bx bxs-book",
    link: "/client",
  },
  /*{
    id: 11,
    label: "Client App Admin",
    link: "/client-App-Admin",
    icon: "bxs-key",
    
  },*/
  /*{
    id: 12,
    label: "Libraries",
    link: "/libraries",
    icon: "bx bxs-book",
  },*/
  /*{
    id: 21,
    label: "Resources",
    link:"/resources",
    icon: "bx bxl-microsoft",
  },*/
  /*{
    id: 29,
    label: "Messaging",
    link:"/messaging",
    icon: "bx-envelope",
  },*/
  /*{
    id: 34,
    label: "Reports",
    link:"/reports",
    icon: "bx-receipt",
  },*/
  /*{
    id: 37,
    label: "Account",
    link:"/account",
    icon: "bx-user",
  },*/
  /*{
    id: 42,
    label: "Company Info",
    link: "/companyInfo",
    icon: "bx bx-spreadsheet",
  },*/
  /*{
    id: 46,
    label: "Change Password",
    link:"/changePassword",
    icon: "bx bxs-lock-alt",
  },*/
  /*{
    id: 50,
    label: "Settings",
    link:"/settings",
    icon: "bx bx-dots-vertical",
  },*/
  /*{
    id: 54,
    label: "menuitems.pages.text",
    isTitle: true,
  }*/
  /*{
    id: 55,
    label: "Account",
    icon: "bx-user-circle",
    badge: {
      variant: "success",
      text: "menuitems.authentication.badge",
    },
    subItems: [
      {
        id: 56,
        label: "menuitems.authentication.list.login",
        link: "/auth/login-1",
        parentId: 55,
      },
      {
        id: 57,
        label: "menuitems.authentication.list.login-2",
        link: "/auth/login-2",
        parentId: 55,
      },
      {
        id: 58,
        label: "menuitems.authentication.list.register",
        link: "/auth/register-1",
        parentId: 55,
      },
      {
        id: 59,
        label: "menuitems.authentication.list.register-2",
        link: "/auth/register-2",
        parentId: 55,
      },
      {
        id: 60,
        label: "menuitems.authentication.list.recoverpwd",
        link: "/auth/recoverpwd",
        parentId: 55,
      },
      {
        id: 61,
        label: "menuitems.authentication.list.recoverpwd-2",
        link: "/auth/recoverpwd-2",
        parentId: 55,
      },
      {
        id: 62,
        label: "menuitems.authentication.list.lockscreen",
        link: "/auth/lock-screen",
        parentId: 55,
      },
      {
        id: 63,
        label: "menuitems.authentication.list.lockscreen-2",
        link: "/auth/lock-screen-2",
        parentId: 55,
      },
      {
        id: 64,
        label: "menuitems.authentication.list.confirm-mail",
        link: "/auth/confirm-mail",
        parentId: 55,
      },
      {
        id: 65,
        label: "menuitems.authentication.list.confirm-mail-2",
        link: "/auth/confirm-mail-2",
        parentId: 55,
      },
      {
        id: 66,
        label: "menuitems.authentication.list.verification",
        link: "/auth/email-verification",
        parentId: 55,
      },
      {
        id: 67,
        label: "menuitems.authentication.list.verification-2",
        link: "/auth/email-verification-2",
        parentId: 55,
      },
      {
        id: 68,
        label: "menuitems.authentication.list.verification-step",
        link: "/auth/two-step-verification",
        parentId: 55,
      },
      {
        id: 69,
        label: "menuitems.authentication.list.verification-step-2",
        link: "/auth/two-step-verification-2",
        parentId: 55,
      },
    ],
  },*/
];
